#navbar {
  box-sizing: border-box;
  padding: 1em;
  font-weight: 700;
  color: white;
  width: 100%;
  font-size: 1.1em;
  text-transform: uppercase;

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    column-gap: 2em;

    li {
      display: inline-block;

      a {
        color: inherit;
        border-bottom: none
      }
    }
    
    li:first-child img {
      height: 1em;
      transform: scale(1.5);
    }
    
  }

  input#menu {
    display: none;
  }
  label#menu {
    display: none;
  }
}

@media (min-width: 800px) {
  #navbar {
    position: absolute;
    top: 0;
    left: 0;
    
    ul {
      flex-direction: row;
      color: black;

      li:last-child {
        margin-left: auto;
      }
    }
  }
}

@media (max-width: 799px) {
  #navbar {
    ul {
      flex-direction: column;
      row-gap: 1.5em;
      width: 100%;
    }

    li {
      margin: 0 auto;
    }
    
    ul {
      display: none;
    }

    label#menu {
      display: block;

      .bar1, .bar2, .bar3 {
        width: 2em;
        height: .3em;
        background-color: #fff;
        margin: .4em auto;
        transition: 0.4s;
      }
    }
  
    input:checked ~ .menu-content {
      display: flex;
    }
    input:checked ~ label#menu {
      margin-bottom: 2em;
    }

    input:checked ~ #menu > .bar1 {
      transform: translate(0, .7em) rotate(-45deg);
    }
    input:checked ~ #menu > .bar2 {
      opacity: 0;
    }
    input:checked ~ #menu > .bar3 {
      transform: translate(0, -.7em) rotate(45deg);
    }
  }
}
