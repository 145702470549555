#header {
  #banner {
    background-image: url("/assets/images/banner.svg");
    background-repeat: no-repeat;
    background-size: 100%;
    height: 25vw;
    max-width: 100%;
  }

  margin-bottom: 5em;
}

#main-wrapper {
  p img {
    max-width: 100%;
  }

  p, li {
    text-align: justify;
    text-justify: inter-word;
  }
}

#content-wrapper {
  max-width: 850px;
  margin-left: auto;
  margin-right: auto;
  padding: 0 1em;

  animation-duration: 0.6s;
  animation-name: main-wrapper-slide-in;
}

@keyframes main-wrapper-slide-in {
    from {
        margin-top: -50px;
        opacity: 0;
    }

    to {
        margin-top: 0px;
        opacity: 1;
    }
}
