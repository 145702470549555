#footer {
  color: #9a9a9a;
  width: 100%;
  text-align: center;
  box-sizing: border-box;
  padding: 0 1em;

  blockquote {
    display: inline-block;

    p {
      font-style: italic;

      &:last-of-type {
        margin-bottom: 0;
      }
    }

    footer {
      text-align: right;

      &:before {
        content: "—";
      }
    }
  }
}
