@import "source-sans.scss";

html {
  font-family: "Source Sans Pro", "Noto-Sans", sans-serif;
  font-size: 1.3em;
}

body {
  width: 100%; 
}

h1, h2, h3 {
  font-weight: normal;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 3em;
  line-height: normal;
}

body {
  background-color: #1b1b1b;
  color: white;
  line-height: 1.8em;
}

a {
  color: inherit;
  text-decoration: none;
  border-bottom: 1px dashed;

  &:hover {
    border-bottom: 1px solid;
  }
}

p {
  margin-top: 2em;
}

/* a small grid for when we have many event posters */
.small-pictures {
  display: grid;
  gap: 0.5em;
  grid-template-columns: repeat(1, minmax(0, 1fr));

  @media (min-width: 800px) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  a {
    /* avoid sizing bug */
    line-height: 0;
  }

  a img {
    /* hover clickable hint */
    transition: 0.2s cubic-bezier(.42,0,.56,.94) transform, 0.2s cubic-bezier(.42,0,.56,.94) box-shadow;
    &:hover, &:focus, &:active {
      transform: scale(1.03);
      box-shadow: 0px 0px 0.5em rgba(0, 0, 0, 0.35);
    }
  }

}
.small-pictures img, img.big-picture {
  min-width: 45%;
  width: 100%;
  box-shadow: 0px 0px 0.3em rgba(0, 0, 0, 0.2);
  border-radius: 10px;
}
